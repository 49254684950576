import React, { memo, useMemo } from "react"

import { useCore } from "../../hooks/useCore"

export const withGeneric = Component =>
  memo(({ name = "Generic", page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const content = sanityContent(page?.content)
    const title = page?.title

    Component.displayName = name
    return useMemo(() => <Component content={content} title={title} />, [content, title])
  })
