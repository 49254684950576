import React from "react"

import { withGeneric } from "./withGeneric"
import { StyledContainer } from "../Styled/Container"
import { RichText } from "../Styled/Text"
import { Page, Title } from "./styledGeneric"

export const Generic = withGeneric(
  ({ title, content }): JSX.Element => (
    <Page>
      <StyledContainer width={`lg`}>
        <Title as={`h1`} align={`center`} colour={`orange`}>
          {title}
        </Title>
        <RichText>{content}</RichText>
      </StyledContainer>
    </Page>
  )
)
