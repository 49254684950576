import React from "react"
import { graphql } from "gatsby"

import { Generic as Template } from "../components/Generic/Generic"

export const query = graphql`
  query ($id: String!) {
    page: sanityPageGeneric(_id: { eq: $id }) {
      title
      slug {
        current
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Template {...props} {...data} />

export default Component
